<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-gutter-md q-pa-md">
        <q-select
          outlined
          stack-label
          v-model="selTingkat"
          label="Tingkat"
          :options="tingkat"
        />
        <q-select
          outlined
          stack-label
          :disable="selTingkat == null"
          v-model="selEkskul"
          label="Ekskul"
          :options="ekskul"
        />
        <div>
          <q-btn
            unelevated
            :disable="selTingkat == null || selEkskul == null"
            color="primary"
            @click="prepareNilai"
            class="full-width"
            label="Tampilkan"
          ></q-btn>
        </div>
      </q-card-section>

      <q-markup-table
        flat
        wrap-cells
        dense
        separator="horizontal"
        class="stickyTable no-border-radius q-mt-md bg-grey-4"
        style="height:calc(100vh - 352px);"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr class="text-left">
            <th>No</th>
            <th>Nama</th>
            <th>
              Nilai
              <q-btn
                :disable="siswa.length == 0"
                flat
                class="q-ml-xs"
                color="white"
                dense
                size="sm"
                icon="add"
                @click="masal = true"
              ></q-btn>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-if="selTingkat == null">
            <td class="text-center" colspan="3">
              Silahkan pilih Kelas Dahulu
            </td>
          </tr>
          <tr v-else-if="selEkskul == null">
            <td class="text-center" colspan="3">
              Silahkan pilih Ekskul dahulu kemudian tekan tombol 'Tampilkan'
            </td>
          </tr>

          <tr v-for="(val, i) in siswa" :key="i">
            <td>{{ i + 1 }}</td>
            <td>
              {{ val.nama }}
            </td>
            <template>
              <q-td>
                {{ formatNilai(val.nilai) }}
                <q-popup-edit :value="nilai" title="Masukan Nilai">
                  <q-input type="number" v-model="val.nilai" dense autofocus />
                </q-popup-edit>
              </q-td>
            </template>
          </tr>
        </tbody>
      </q-markup-table>

      <q-card-actions class="q-pa-none">
        <q-btn
          filled
          :disable="siswa.length == 0"
          color="negative"
          class="full-width no-border-radius"
          @click="simpan()"
          >simpan</q-btn
        >
      </q-card-actions>
      <q-dialog v-model="masal">
        <q-card>
          <q-card-section>
            <q-input
              label="Nilai Masal"
              v-model="nilai"
              type="number"
              stack-label
            ></q-input>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat color="primary" label="Batal" v-close-popup></q-btn>
            <q-btn
              unelevated
              color="primary"
              label="Input"
              @click="bulkInput"
              v-close-popup
            ></q-btn>
          </q-card-actions>
        </q-card>
      </q-dialog>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      user: {},
      masal: false,

      nilai: 0,
      siswa: [],

      searchTerm: "",

      selTingkat: null,
      tingkat: [],

      selEkskul: null,
      ekskul: [],
    };
  },
  computed: {
    filMapel() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  async mounted() {
    await this.getUser();
    await this.prepareData();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async simpan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http.put(`/penilaian/input_ekskul/addnilai`, this.siswa);
      this.$q.loading.hide();
      this.$q.notify({
        message: "Nilai Ekskul Berhasil Disimpan!",
        color: "positive",
      });
    },
    bulkInput() {
      let cpy = JSON.parse(JSON.stringify(this.siswa));

      for (let item of cpy) {
        item.nilai = this.nilai;
      }
      this.siswa = cpy;
    },
    formatNilai(nilai) {
      if (nilai == null) return "-";
      return nilai;
    },
    async prepareData() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/input_ekskul/gettingkat/${localStorage.getItem("jenjang")}`
      );

      let tingkat = [];
      for (let item of resp.data) {
        tingkat.push(item.tingkat);
      }
      this.tingkat = tingkat;

      resp = await this.$http.get(
        `/penilaian/input_ekskul/getekskul/${localStorage.getItem("jenjang")}/${
          this.user.id
        }/${this.user.is_wali_kelas}/${this.user.is_super_user}`
      );

      this.ekskul = resp.data;
      this.$q.loading.hide();
    },
    async prepareNilai() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http
        .get(
          `/penilaian/input_ekskul/getnilai/${this.selTingkat}/${
            this.selEkskul.value
          }/${localStorage.getItem("id_tahun_ajaran")}/${this.user.id}/${
            this.user.is_pembina_ekskul
          }/${this.user.is_super_user}`
        )
        .then((resp) => {
          let carier = [];
          for (let item of resp.data) {
            let temp = {};
            temp.nama = item.nama;
            temp.id_nilai_ekskul = item.id_nilai_ekskul;
            if (item.nilai == null) temp.nilai = null;
            else temp.nilai = parseFloat(item.nilai);
            carier.push(temp);
          }

          this.siswa = carier;
        });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
